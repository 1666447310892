import { createStore as reduxCreateStore, compose } from 'redux'
import { devTools } from './reduxDevTools'
import rootReducer from 'ducks'

const initialState = {}

const storeEnhancements = compose(devTools)

const createStore = () =>
  reduxCreateStore(rootReducer, initialState, storeEnhancements)
export default createStore
