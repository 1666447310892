function conditionallyLoadAnalytics({ writeKey, destinationPreferences }) {
  let isAnythingEnabled = false
  var analytics = (window.analytics = window.analytics || [])

  if (!destinationPreferences) {
    if (!analytics.initialized) {
      analytics.load(writeKey)
    }
    return
  }

  const destinationPreferencesKeys = Object.keys(destinationPreferences)

  for (const destination of destinationPreferencesKeys) {
    const isEnabled = destinationPreferences[destination]
    if (isEnabled) {
      isAnythingEnabled = true
    }
  }

  if (isAnythingEnabled) {
    analytics.load(writeKey, { integrations: destinationPreferences })
  }
}

async function fetchDestinationForWriteKey(writeKey) {
  const res = await window.fetch(
    `https://cdn.segment.com/v1/projects/${writeKey}/integrations`,
  )

  if (!res.ok) {
    throw new Error(
      `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`,
    )
  }

  const destinations = await res.json()

  for (const destination of destinations) {
    destination.id = destination.creationName
    delete destination.creationName
  }

  return destinations
}

async function fetchDestinations(surgeKey) {
  let destinations = await fetchDestinationForWriteKey(surgeKey)

  destinations = [
    ...destinations
      .reduce((map, item) => {
        if (item.id === 'Repeater') return map
        map.has(item['id']) || map.set(item['id'], item)
        return map
      }, new Map())
      .values(),
  ]

  return destinations
}

const loadConsentDecision = async () => {
  return new Promise(resolve => {
    let counter = 0
    let consentDecision = ''
    const defaultConsent = ',C0001,C0002,C0003,C0004,'

    const interval = setInterval(() => {
      const shouldClearInterval = consentDecision || counter >= 10

      if (!shouldClearInterval) {
        counter += 1
        return (consentDecision = window.OnetrustActiveGroups)
      } else {
        consentDecision = consentDecision || defaultConsent

        resolve(consentDecision)
        clearInterval(interval)
      }
    }, 500)
  })
}

export const loadSegment = async segmentKey => {
  const destinations = await fetchDestinations(segmentKey)
  const consentDecision = await loadConsentDecision()

  const categoryMapper = {
    Analytics: 'C0002',
    Advertising: 'C0005',
    'Email Marketing': 'C0003',
    'Tag Managers': 'C0001',
  }

  const destinationPreferences = destinations
    .map(({ category = '', id = '' }) => {
      const hasPermission = consentDecision.includes(categoryMapper[category])

      return {
        [id]: hasPermission,
      }
    })
    .reduce((acc, cur) => {
      return {
        ...acc,
        ...cur,
      }
    }, {})

  conditionallyLoadAnalytics({
    writeKey: segmentKey,
    destinationPreferences,
  })
}
