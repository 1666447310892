import { handleActions } from 'redux-actions'

const initialState = {
  menuIsOpened: false,
  sidebarIsOpened: false,
  feedbackFormIsTrue: false,
}

export const resetContributeSidebar = data => {
  return {
    type: 'SET_UI',
    data,
  }
}

export const toggleMenu = menuIsOpened => {
  return {
    type: 'TOGGLE_MENU',
    data: {
      menuIsOpened,
    },
  }
}

export const toggleSidebar = sidebarIsOpened => {
  return {
    type: 'TOGGLE_MENU',
    data: {
      sidebarIsOpened,
    },
  }
}

export const toggleFeedbackForm = feedbackFormIsTrue => {
  return {
    type: 'TOGGLE_FEEDBACK',
    data: {
      feedbackFormIsTrue,
    },
  }
}

export default handleActions(
  {
    SET_UI: (state, { data }) => {
      return {
        ...state,
        ...data,
      }
    },
    TOGGLE_MENU: (state, { data }) => {
      return {
        ...state,
        ...data,
      }
    },
    TOGGLE_SIDEBAR: (state, { data }) => {
      return {
        ...state,
        ...data,
      }
    },
    TOGGLE_FEEDBACK: (state, { data }) => {
      return {
        ...state,
        ...data,
      }
    },
  },
  initialState,
)
