import { isTablet, isMobile, isDesktop } from 'utils/getDevice'
import R from 'ramda'

const getDeviceByScreenSize = () => {
  const devices = {
    mobile: isMobile,
    tablet: isTablet,
    desktop: isDesktop,
  }

  const byDevice = (acc, device) => {
    const matchedDevice = R.prop(device, devices)

    if (matchedDevice()) return device

    return acc
  }

  return R.pipe(
    R.keys,
    R.reduce(byDevice, ''),
    R.defaultTo('mobile')
  )(devices)
}

export default getDeviceByScreenSize
