import initSegment from 'utils/segment/initSegment'
import events from 'utils/segment/events'
import R from 'ramda'

const segmentDispatcher = (event = '', payload = {}, props) => {
	return new Promise((resolve, reject) => {
		return initSegment().then(analytics => {
			const currentEvents = events(analytics)
			const currentEvent = R.prop(event, currentEvents)

			if (currentEvent) {
				console.log(`[ Segment ] - ${event} has been successfully dispatched!`)
				//resolve(currentEvent(payload, props))
			}

			if (!currentEvent) {
				console.log(`[ Segment ] - ${event} method is not defined!`)
				reject(`[ Segment ] - ${event} method is not defined!`)
			}
		})
	})
}

export default segmentDispatcher
