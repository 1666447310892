import R from 'ramda'

const createPageAlias = ({ location, currentRouteName = 'Home' }) => {
  if (location) {
    const { pathname, query } = location

    const options = R.pipe(
      R.keys,
      R.reduce((acc, key) => {
        const queryValue = R.prop(key, query)

        if (queryValue) return `${acc}${key}_${queryValue}`

        return acc
      }, '')
    )(query)

    return `${currentRouteName.toUpperCase()}_${pathname}${
      options ? `_${options}` : ''
    }`
  }
}

export default createPageAlias
