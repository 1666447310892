import { Provider } from 'react-redux'

import createStore from 'utils/createStore'
import fontObserver from 'utils/fontObserver'
import isClient from 'utils/isClient'

import AppLayout from 'layout/AppLayout'

import 'styles/main.styl'

isClient() && fontObserver([
  {
    name: 'Rubik',
    weight: 400,
  },
  {
    name: 'Rubik',
    weight: 600,
  },
])

Promise.resolve(
  import(
    /* webpackChunkName: "secondaryAssets" */ '../../utils/secondaryAssets/index.js'
  ),
).then(res => {
  res.default()
  console.log('Secondary scripts loaded!')
})

const Root = ({ element }) => {
  const store = createStore()

  return (
    <Provider store={store}>
      <AppLayout element={element} />
    </Provider>
  )
}

export default Root