// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/About/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-b-2-b-index-js": () => import("./../../../src/pages/B2B/index.js" /* webpackChunkName: "component---src-pages-b-2-b-index-js" */),
  "component---src-pages-design-system-index-js": () => import("./../../../src/pages/DesignSystem/index.js" /* webpackChunkName: "component---src-pages-design-system-index-js" */),
  "component---src-pages-financial-health-index-js": () => import("./../../../src/pages/FinancialHealth/index.js" /* webpackChunkName: "component---src-pages-financial-health-index-js" */),
  "component---src-pages-for-your-company-index-js": () => import("./../../../src/pages/ForYourCompany/index.js" /* webpackChunkName: "component---src-pages-for-your-company-index-js" */),
  "component---src-pages-form-hero-landing-index-js": () => import("./../../../src/pages/FormHeroLanding/index.js" /* webpackChunkName: "component---src-pages-form-hero-landing-index-js" */),
  "component---src-pages-form-hero-landing-two-index-js": () => import("./../../../src/pages/FormHeroLandingTwo/index.js" /* webpackChunkName: "component---src-pages-form-hero-landing-two-index-js" */),
  "component---src-pages-form-success-index-js": () => import("./../../../src/pages/FormSuccess/index.js" /* webpackChunkName: "component---src-pages-form-success-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-management-index-js": () => import("./../../../src/pages/Management/index.js" /* webpackChunkName: "component---src-pages-management-index-js" */),
  "component---src-pages-onze-manager-index-js": () => import("./../../../src/pages/OnzeManager/index.js" /* webpackChunkName: "component---src-pages-onze-manager-index-js" */),
  "component---src-pages-privacy-police-index-js": () => import("./../../../src/pages/PrivacyPolice/index.js" /* webpackChunkName: "component---src-pages-privacy-police-index-js" */),
  "component---src-pages-safety-index-js": () => import("./../../../src/pages/Safety/index.js" /* webpackChunkName: "component---src-pages-safety-index-js" */)
}

