import { combineReducers } from 'redux'

import ui from './ui.js'
import cms from './cms.js'
import lazyImages from './lazyImages.js'

const rootReducer = combineReducers({
  ui,
  cms,
  lazyImages,
})

export default rootReducer
