import { handleActions } from 'redux-actions'

const initialState = {
	fallback: false,
}

export const setFallback = fallback => {
	return {
		type: 'SET_FALLBACK',
		fallback,
	}
}

export default handleActions(
	{
		SET_FALLBACK: (state, { fallback }) => {
			return {
				fallback,
			}
		},
	},
	initialState
)
