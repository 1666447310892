const createScript = (cdn, callback, config) => {
  const script = document.createElement('script')

  if (cdn) script.src = cdn
  config && Object.keys(config).forEach(prop => (script[prop] = config[prop]))
  document.body.appendChild(script)

  const onScriptLoad = () => {
    if (callback) {
      callback(script)
    }
  }

  script.onload = onScriptLoad
}

export default createScript
