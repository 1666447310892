import R from 'ramda'
import { useEffect } from 'react'

import segmentDispatcher from 'utils/segment/dispatcher'
import scrollTracker from 'utils/segment/handleScrollTrackerCreator'

const handleScroll = scrollTracker()

const useGlobalTrackings = ({ location }) => {
	const pathname = R.prop('pathname', location)

  useEffect(() => {
    segmentDispatcher('pageView')

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [pathname])
}

export default useGlobalTrackings
