import dispatcher from 'utils/segment/dispatcher'

let scrollDown = 0

const sent = {
  0: false,
  25: false,
  50: false,
  75: false,
  100: false,
}

export default track => {
  const handler = () => {
    if (!document.body) return

    const windowHeight = document.body.scrollHeight - window.innerHeight

    if (windowHeight < 0) return

    if (!sent[0]) {
      dispatcher('trackScroll', {
        scroll: scrollDown,
        scrollLenght: '0%',
      })

      sent[0] = true
    }

    if (scrollDown >= windowHeight * 1) {
      if (!sent[100]) {
        dispatcher('trackScroll', {
          scroll: scrollDown,
          scrollLenght: '100%',
        })
      }

      sent[100] = true
      window.removeEventListener('scroll', handler)
    } else if (scrollDown >= windowHeight * 0.75) {
      if (!sent[75]) {
        dispatcher('trackScroll', {
          scroll: scrollDown,
          scrollLenght: '75%',
        })
      }

      sent[75] = true
    } else if (scrollDown >= windowHeight * 0.5) {
      if (!sent[50]) {
        dispatcher('trackScroll', {
          scroll: scrollDown,
          scrollLenght: '50%',
        })
      }

      sent[50] = true
    } else if (scrollDown >= windowHeight * 0.25) {
      if (!sent[25]) {
        dispatcher('trackScroll', {
          scroll: scrollDown,
          scrollLenght: '25%',
        })
      }

      sent[25] = true
    }
    scrollDown = window.pageYOffset
  }
  return handler
}
