import createScript from './createScript'
import setDefaultHeaders from './setDefaultHeaders'
import { RECAPTCHA_KEY } from 'gatsby-env-variables'

export const loadReCaptcha = () => {
	const cb = () => console.log('[ System ] - reCaptcha is initialized')
	createScript(`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`, cb)
}

export const withReCaptcha = (handleSuccess) => {
	if (!grecaptcha) {
		return console.log('[ System ] reCaptcha error: not loaded.')
	}

	grecaptcha.ready(_ => {
		grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' })
			.then(rcToken => {
				setDefaultHeaders({ 'Recaptcha-Token': `${rcToken}` })
				handleSuccess && handleSuccess()
			})
			.catch(e => {
				console.error('[ System ] reCaptcha error: ', e)
			})
	})
}
