import { useDispatch } from 'react-redux'
import { setCmsData } from 'ducks/cms'
import { graphql, useStaticQuery } from 'gatsby'

const useCmsData = () => {
  const dispatch = useDispatch()
  const gatsbyRepoData = useStaticQuery(graphql`
    query {
      cmsData {
        body
      }
    }
  `)

	const cmsData = JSON.parse(gatsbyRepoData.cmsData.body)
	dispatch(setCmsData(cmsData))
}

export default useCmsData
