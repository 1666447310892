import { Helmet } from 'react-helmet'
import { env } from 'gatsby-env-variables'
import { Location } from '@reach/router'

import enhancer from './hooks'

const AppLayoutComponent = enhancer(({ element }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          dir: 'ltr',
          lang: 'en',
          "data-react-helmet": 'dir,lang'
        }}>
        <meta charSet="utf-8" />
        <title>Onze - Previdência Reinventada</title>
        <meta name="robots" content={env === 'production' ? 'all' : 'none'} />
        <link rel="icon" href="/images/favicon.png" />
        <link rel="canonical" href="https://www.onze.com.br/" />
        <meta
          name="description"
          content="A Onze é primeira fintech que traz uma solução de saúde financeira com acumulação de patrimônio para colaboradores da sua empresa, por meio de uma previdência diferente e moderna. Onze, construa o seu melhor futuro."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Onze" />
        <meta
          property="og:description"
          content="A Onze é primeira fintech que traz uma solução de saúde financeira com acumulação de patrimônio para colaboradores da sua empresa, por meio de uma previdência diferente e moderna. Onze, construa o seu melhor futuro."
        />
        <meta property="og:url" content="https://www.onze.com.br/" />
        <meta property="og:site_name" content="Onze Investimentos" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="A Onze é primeira fintech que traz uma solução de saúde financeira com acumulação de patrimônio para colaboradores da sua empresa, por meio de uma previdência diferente e moderna. Onze, construa o seu melhor futuro."
        />
        <meta name="twitter:title" content="Onze Investimentos" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <div className="app-layout">{element}</div>
    </>
  )
})

const AppLayout = ({ element }) => (
  <Location>
    {location => <AppLayoutComponent {...location} element={element} />}
  </Location>
)

export default AppLayout
