import getDeviceByScreenSize from 'utils/segment/getDeviceByScreenSize'
import createPageAlias from 'utils/segment/createPageAlias'

const getBaseSegmentConfig = (payload = {}) => {
	const currentPageName = createPageAlias({
		location: window.location,
		currentRouteName: 'landing',
	})

	return {
		device: getDeviceByScreenSize(),
		pagePath: window.location,
		currentPageName,
		...payload
	}
}

export default analytics => {

  const track = ({ name, payload }) => {
		const context = getBaseSegmentConfig(payload)

    if (!analytics) {
      console.error('Segment track is not defined!')
      return {}
    }

    return analytics.track(name, context)
  }

  const page = ({ name, payload }) => {
		const context = getBaseSegmentConfig(payload)

    if (!analytics) {
      console.error('Segment track is not defined!')
      return {}
    }

    return analytics.page(name, context)
  }

  return {
    pageView: (payload = {}) => {
			return page({
				name: 'Onze Landing',
				payload,
			})
    },

    trackScroll: (payload = {}) => {
      return track({
        name: 'Page Scrolled',
        payload,
      })
    },

    buttonClick: (payload) => {
      return track({
        name: 'Button Clicked',
        payload,
      })
    },

    filterChanged: (payloadb) => {
      return track({
        name: 'Acquirers Filter Element Changed',
        payload,
      })
    },
    customConversion: (payload) => {
      return track({
        name: 'Awareness Custom Conversion',
        payload,
      })
    },
    corporateLeadStarted: (payload) => {
      return track({
        name: 'Corporate Lead Started',
        payload,
      })
    },
    corporateLeadCompleted: (payload) => {
      return track({
        name: 'Corporate Lead Completed',
        payload,
      })
    },
    individualLeadCompleted: (payload) => {
      return track({
        name: 'Individual Lead Completed',
        payload,
      })
    },
  }
}
