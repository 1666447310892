import { handleActions } from 'redux-actions'

const initialState = {}

export const setCmsData = data => {
  return {
    type: 'SET_CMS',
    data,
  }
}

export default handleActions(
  {
    SET_CMS: (state, { data }) => {
      return {
        ...state,
        ...data,
      }
    },
  },
  initialState,
)
