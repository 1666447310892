import { useEffect } from 'react'
import { loadReCaptcha } from 'utils/reCaptcha'

const useReCaptcha = () => {
	useEffect(() => {
		loadReCaptcha()
	}, [])

	return {}
}

export default useReCaptcha
